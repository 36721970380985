<template lang="pug">
    #Lista
        ul
            li(v-for="item in lists" v-if="item.snippet.title == 'Coamo TV' || item.snippet.title == 'Momento Coamo' || item.snippet.title == 'Reuniões Técnicas' || item.snippet.title == 'Palestras Programa Coamo+ Cooperativismo'")
                a(:href="`https://www.youtube.com/playlist?list=${item.id}`")
                    .img 
                        img(:src="item.snippet.thumbnails.medium.url").image
                    .container
                        .title
                            h2.titulo {{ item.snippet.title }}
                        .textContainer
                            .icon    
                                img(src="@images/comunicacao/coamo-logo-branco.png").logo
                            .text
                                p {{ item.snippet.channelTitle}}
</template>

<script>
import { props } from '@/mixins/component'
import Axios from 'axios'

export default {
    name: "lista-reproducao",
    props,

    data() {
        return {
            lists: {},
            channelId: '',
            apiYoutube: '',
        }
    },
    methods: {
        async getLists() {
            const axios = Axios.create()
            const response = await axios.get(`https://youtube.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${this.channelId}&max_results=13&key=${this.apiYoutube}`)
            this.lists = response.data.items
        },
        getSettings() {
            for (let setting of this.settings) {
                if (setting.attribute === 'apiYoutube') this.apiYoutube = setting.value;
                if (setting.attribute === 'channelIdYoutube') this.channelId = setting.value;
            }
        },
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
    computed: {
        settings() {
            return this.$store.state.settings
        },
    },
    watch: {
        settings: function () {
            this.getSettings()
            this.lists = this.getLists()
        }
    }
}
</script>

<style lang="stylus" scoped src="./ListaReproducao.styl"></style>
